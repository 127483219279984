import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Tag from "../Tag"

const TagsWrapper = styled.ul`
  font-size: 0.8em;
  list-style-type: none;
  margin: 1em 0;
  padding: 0;
  display: flex;

  > * {
    margin: 0;
    &:not(:first-child) {
      margin-left: 1ch;
    }
  }
`

const TagList = ({ tags }) => (
  <TagsWrapper>
    {tags.map(tag => (
      <li key={tag}>
        <Tag>{tag}</Tag>
      </li>
    ))}
  </TagsWrapper>
)

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default TagList
