import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TagList from "../components/TagList"
import { TextContainer } from "../components/Container"
import Breadcrumbs from "../components/Breadcrumbs"

const Hr = styled.hr`
  color: inherit;
  border: 1px solid currentColor;
`

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html, timeToRead } = markdownRemark
  const timeToReadString = `${timeToRead} ${
    timeToRead === 1 ? "min" : "mins"
  } to read`
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <TextContainer as="article">
        <Breadcrumbs
          links={[
            {
              text: "Blog",
              path: "/blog",
            },
            {
              text: frontmatter.title,
              path: `/blog/${frontmatter.slug}`,
              current: true,
            },
          ]}
        />
        <h1>{frontmatter.title}</h1>
        <p>{frontmatter.summary}</p>
        <p>
          {frontmatter.date_published} ∙ {timeToReadString}
        </p>
        <TagList tags={frontmatter.topics} />
        <Hr />
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </TextContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        date_published(formatString: "MMMM DD, YYYY")
        summary
        title
        topics
        slug
      }
      timeToRead
      html
    }
  }
`
