import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const BreadcrumbsNav = styled.nav`
  margin: 1em 0;
`

const BreadcrumbsList = styled.ol`
  list-style-type: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const BreadcrumbsListItem = styled.li`
  display: inline;
  &:not(:first-child):before {
    content: "/";
    margin: 0 1ch;
  }
`

const Breadcrumbs = ({ links = [] }) => (
  <BreadcrumbsNav aria-label="Breadcrumbs">
    <BreadcrumbsList>
      {links.map(({ path, text, current }) => (
        <BreadcrumbsListItem>
          <Link to={path} {...(current && { "aria-current": "page" })}>
            {text}
          </Link>
        </BreadcrumbsListItem>
      ))}
    </BreadcrumbsList>
  </BreadcrumbsNav>
)

export default Breadcrumbs
